<template>
    <div class="Modelmaking">
        <div class="countdown clearfix">
            <div class="clearfix" style="width:100%">
                <div class="div_titles fl">模型制作</div>
                <div class="count_span fl" v-show="countNum==1">
                    <span>{{count.name}}倒计时：</span>
                    <span class="timer_s">{{timer}}</span>
                </div>
                <button class="count_but fr" @click="submit()" v-show="searchVal<8">提交</button>
            </div>
        </div>
        <div class="Model_div">
            <ul class="m_ul clearfix">
                <li class="fl">
                    <p class="li_title"> <span><i class="iconfonts icons-xinghaoguige"></i> </span> 参考模板</p>
                    <div class="li_content clearfix">
                        <div class="fl li_div_img">
                            <el-carousel>
                                <el-carousel-item v-for="(item,index) in forms.template.value.img" :key="item">
                                    <el-image :src="item" :preview-src-list="forms.template.value.img"  :initial-index="index" alt="" />
                                </el-carousel-item>
                            </el-carousel>
                            <!-- <img :src="forms.template.value.img" alt=""> -->
                        </div>
                        <div class="fl div_cont">
                            <p class="div_name">{{forms.template.value.name}}</p>
                            <p class="div_jianj">
                                <span>{{forms.template.value.desc}}</span>
                            </p>
                            <p class="p_tit" v-for="(item,index) in forms.template.value.tag" :key="index">
                                <strong>{{item.name}}</strong>：
                                <span>{{item.val}}</span>
                            </p>
                        </div>
                        <!-- <button class="div_button">修改规格</button> -->
                    </div>
                </li>
                <li class="fl" v-for="(items,indexs) in forms.p1.values" :key="indexs">
                    <i class="el-icon-edit-outline delicons" v-show="items.userId == userId" @click="updateBox(items)"></i> <!--组员修改-->
                    <i class="el-icon-delete-solid delicon" v-show="form.leader==true" @click="delBox(items)"></i>  <!--组长删除-->
                    <i class="el-icon-delete-solid delicon" v-show="form.leader!=true && items.userId == userId" @click="delBoxs(items)"></i> <!--组员删除-->
                    <p class="li_title"> <span><i class="iconfonts icons-xinghaoguige"></i> </span>产品规格{{indexs+1}}</p>
                    <div class="li_content clearfix">
                        <div class="fl li_div_img">
                            <el-carousel>
                                <el-carousel-item v-for="(imgm,index) in items.img" :key="imgm">
                                    <el-image :src="imgm" :preview-src-list="items.img" :initial-index="index"  alt="" />
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="fl div_cont">
                            <p class="div_name">{{items.name}}</p>
                            <p class="div_jianj">
                                <span>{{items.desc}}</span>
                            </p>
                            <p class="p_tit" v-for="(itemss,indexss) in items.tag" :key="indexss">
                                <strong>{{itemss.name}}</strong>：
                                <span>{{itemss.val}}</span>
                            </p>
                        </div>
                    </div>
                </li>
                <li class="fl add_li" v-show="forms.p1.values.length<5">
                    <i class="iconfonts icons-jiahao"  @click="dialogVisible = true"></i>
                </li>
            </ul>
        </div>
        <!-- 弹窗 -->
        <div class="moban_popup">
            <el-dialog
                title="填写规格"
                :visible.sync="dialogVisible"
                width="700px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('ruleForm')"></i>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small" @submit.native.prevent>
                    <el-form-item label="规格名称" prop="name">
                        <el-input v-model="ruleForm.name" placeholder="请输入规格名称" maxlength="10" show-word-limit @keyup.enter.native="enterInput($event)"></el-input>
                    </el-form-item>
                    <el-form-item label="规格简介" prop="desc">
                        <el-input type="textarea" v-model="ruleForm.desc"  placeholder="请输入规格简介" maxlength="30" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item prop="img" label="创意图片（最多可上传5张）">
                        <el-upload
                            class="avatar-uploader"
                            list-type="picture-card"
                            :limit="5"
                            :file-list="fileList"
                            multiple
                            :action="this.$store.state.uploads"
                            accept=".jpg, .jpeg, .png,"
                            :on-remove="handleRemove"
                            :on-success="handsuccessForm"
                            :before-upload="beforeAvatarUpload"
                            :class="{hide:hideUpload}"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item>
                        <el-row v-for="(item, index) in ruleForm.tag" :key="index" style="margin-bottom: 20px;">
                            <el-col :span="8">
                                <el-form-item :label="`参数${index + 1}`" :prop="`tag.${index}.name`"
                                :rules="[{ required:true, message: '请输入参数名称', trigger: 'blur' }]">
                                    <el-input v-model="item.name" placeholder="例：颜色" maxlength="4" show-word-limit @keyup.enter.native="enterInput($event)"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="1">
                                <div style="width:2px;height:5px">

                                </div>
                            </el-col>
                            <el-col :span="13">
                                <el-form-item label="" :prop="`tag.${index}.val`"
                                :rules="[{ required: true, message: '请输入参数详情', trigger: 'blur' }]">
                                    <el-input v-model="item.val" placeholder="例：黑色" maxlength="20" show-word-limit @keyup.enter.native="enterInput($event)"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="2" style="text-align:center; font-size: 16px" >
                                <i class="el-icon-close icaa" @click="removeItem(index)" v-show="ruleForm.tag.length>1"></i>
                            </el-col>
                        </el-row>
                        <div class="tab_col_d" @click="addItem" v-show="ruleForm.tag.length<5">
                            <i class="el-icon-plus"></i>
                        </div>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="subxit('ruleForm')">确定</el-button>
                </span>
            </el-dialog>
            <el-dialog
                title="修改规格"
                :visible.sync="dialogVisibleForm"
                width="700px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('Form')"></i>
                <el-form :model="Form" :rules="rules" ref="Form" class="demo-ruleForm" size="small" @submit.native.prevent>
                    <el-form-item label="规格名称" prop="name">
                        <el-input v-model="Form.name" placeholder="请输入规格名称" maxlength="10" show-word-limit @keyup.enter.native="enterInput1($event)"></el-input>
                    </el-form-item>
                    <el-form-item label="规格简介" prop="desc">
                        <el-input type="textarea" v-model="Form.desc"  placeholder="请输入规格简介" maxlength="30" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item prop="img" label="创意图片（最多可上传5张）">
                        <el-upload
                            class="avatar-uploader"
                            list-type="picture-card"
                            :limit="5"
                            :file-list="fileLists"
                            multiple
                            :action="this.$store.state.uploads"
                            accept=".jpg, .jpeg, .png,"
                            :on-remove="handleRemoves"
                            :on-success="handsuccessForms"
                            :before-upload="beforeAvatarUpload"
                            :class="{hides:hideUploads}"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item>
                        <el-row v-for="(item, index) in Form.tag" :key="index" style="margin-bottom: 20px;">
                            <el-col :span="8">
                                <el-form-item :label="`参数${index + 1}`" :prop="`tag.${index}.name`"
                                :rules="[{ required:true, message: '请输入参数名称', trigger: 'blur' }]">
                                    <el-input v-model="item.name" placeholder="例：颜色" maxlength="4" show-word-limit @keyup.enter.native="enterInput1($event)"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="1">
                                <div style="width:2px;height:5px">

                                </div>
                            </el-col>
                            <el-col :span="13">
                                <el-form-item label="" :prop="`tag.${index}.val`"
                                :rules="[{ required: true, message: '请输入参数详情', trigger: 'blur' }]">
                                    <el-input v-model="item.val" placeholder="例：黑色" maxlength="20" show-word-limit @keyup.enter.native="enterInput1($event)"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="2" style="text-align:center; font-size: 16px" >
                                <i class="el-icon-close icaa" @click="removeItems(index)" v-show="Form.tag.length>1"></i>
                            </el-col>
                        </el-row>
                        <div class="tab_col_d" @click="addItems" v-show="Form.tag.length<5">
                            <i class="el-icon-plus"></i>
                        </div>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="dialogVisibleForm = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="subxits('Form')">确定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    props: ['searchVals'],
    data() {
        return {
            dialogVisible:false,
            dialogVisibleForm:false,
            timer:'',
            countNum:0,
            count:{},
            hideUpload:false,
            hideUploads:false,
            fileList:[],
            fileLists:[],
            ruleForm:{
                img:[],
                tag:[{
                    name:'',
                    val:''
                }],
                name:'',
                desc:''
            },
            Form:{
                img:[],
                tag:[{
                    name:'',
                    val:''
                }],
                name:'',
                desc:''
            },
            rules:{
                name:[
                    { required: true, message: '规格名称不能为空', trigger:'blur'}
                ],
                desc:[
                    { required: true, message: '规格简介不能为空', trigger:'blur'}
                ],
                val:[
                    { required: true, message: '内容不能为空', trigger:'blur'}
                ],
                img:[
                    { required: true, message: "请至少上传一张创意图片", trigger: "blur" }
                ]
            },
            forms:{
                template:{
                    value:{
                        tag:[]
                    }
                },
                p1:{
                    values:[]
                }
            },
            userId:'',
            searchVal:0
        }
    },
    methods: {
        getapp(){
            this.searchVal = this.searchVals
            this.userId = this.$store.state.user_id
            this.axios.StuCprojectgets6({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.forms = res.data.data.data
                    var str = this.forms.resource
                    this.$emit('info', str);
                }else{
                    this.$message.error(res.data.message)
                    this.form = {}
                }
            }).catch(err=>{

            })
        },
            // 获取倒计时
        down(){
            this.axios.StudctdGet({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    if(res.data.data!='' && res.data.data!=null && res.data.data!='null'){
                        this.countNum = 1
                        this.count = res.data.data
                        this.time = res.data.data.times
                        clearTimeout(this.timeouts)
                        if(this.count.state==1){
                            this.countDown()
                            this.timeoutss = setTimeout(this.down,5000)
                        }else{
                            let h = parseInt(this.time / (60 * 60) % 24);
                            h = h < 10 ? "0" + h : h
                            let m = parseInt(this.time / 60 % 60);
                            m = m < 10 ? "0" + m : m
                            let s = parseInt(this.time % 60);
                            s = s < 10 ? "0" + s : s
                            this.timer =  m +':'+ s
                            this.timeoutss = setTimeout(this.down,5000)
                        }
                    }else{
                        this.timeout = setTimeout(this.down,3000)
                        this.countNum = 2
                    }
                }else if(res.data.code==-4054){
                    this.timeout = setTimeout(this.down,3000)
                    this.countNum = 2
                    // this.$message.error(res.data.message)
                }else{
                  this.countNum = 2
                }
            }).catch(err=>{

            })
        },
        //倒计时
        countDown(){
            if(this.time>0){
                let h = parseInt(this.time / (60 * 60) % 24);
                h = h < 10 ? "0" + h : h
                let m = parseInt(this.time / 60 % 60);
                m = m < 10 ? "0" + m : m
                let s = parseInt(this.time % 60);
                s = s < 10 ? "0" + s : s
                this.timer =  m +':'+ s
                this.time--
                this.timeouts = setTimeout(this.countDown,1000)
                // setTimeout(this.down,5000)
            }else{
                this.timer = '00' +':'+ '00' +':'+ '00'
            }
        },
        addItems(){
            if(this.Form.tag.length>=5){
                this.$message.warning('最多只能添加5个参数')
            }else{
                this.Form.tag.push({
                    name: '',
                    val: ''
                })
            }
        },
        addItem () {
            if(this.ruleForm.tag.length>=5){
                this.$message.warning('最多只能添加5个参数')
            }else{
                this.ruleForm.tag.push({
                    name: '',
                    val: ''
                })
            }
        },
        updateBox(item){
            this.Form = item
            this.dialogVisibleForm = true
            let sortData = []
            this.Form.img.map(item=>{
                sortData.push(Object.assign({url:item,response:item}))
            })
            this.fileLists = sortData
            this.hideUploads = this.fileLists.length>=5
        },
        subxit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.StuCprojectsets6({
                        token:this.$store.state.token,
                        id:this.$store.state.cla_id,
                        node:'p1',
                        value:this.ruleForm
                    }).then(res=>{
                        if(res.data.code==0){
                            this.$refs[formName].resetFields();
                            this.dialogVisible = false
                            this.getapp()
                            this.fileList = []
                            this.hideUpload = this.fileList.length>=5
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        subxits(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.StuCprojectsets6({
                        token:this.$store.state.token,
                        id:this.$store.state.cla_id,
                        node:'p1',
                        value:this.Form
                    }).then(res=>{
                        if(res.data.code==0){
                            this.$refs[formName].resetFields();
                            this.dialogVisibleForm = false
                            this.getapp()
                            this.fileLists = []
                            this.hideUploads = this.fileLists.length>=5
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        enterInput1(e){
            if (e.which == 13 || e.charCode == 13 || e.keyCode == 13) {
                this.subxits('Form');
            }
        },
        //删除某一条数据
        delBox(item){
            this.$confirm('删除后无法找回哦', '确定删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                    const arr = this.forms.p1.values.filter(infos => infos.date != item.date)
                    this.axios.StuCprojectdels6({
                        id:this.$store.state.cla_id,
                        token:this.$store.state.token,
                        node:'p1',
                        value:arr
                    }).then(res=>{
                        if(res.data.code==0){
                            this.getapp()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 组员删除
        delBoxs(item){
            this.$confirm('删除后无法找回哦', '确定删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                const arr = this.forms.p1.values.filter(infos => infos.date != item.date)
                this.axios.StuCprojectdels6({
                    id:this.$store.state.cla_id,
                    token:this.$store.state.token,
                    node:'p1',
                    value:[{name:item.name}]
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }else{
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 提交
        submit(){
            this.axios.submits6({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
            }).then(res=>{
                if(res.data.code==0){
                    this.getapp()
                    this.$message.success('提交成功')
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        // 输入框回车事件
        enterInput(e){
            if (e.which == 13 || e.charCode == 13 || e.keyCode == 13) {
                this.subxit('ruleForm');
            }
        },
        //验证类型
        beforeAvatarUpload(file) {
            const isimgtype = file.type;
            if (isimgtype === "image/jpg" || isimgtype === "image/png" || isimgtype === "image/jpeg") {
            } else {
                this.$message.error("请上传jpg/png/jpeg格式的图片");
                return false;
            }
        },
        removeItem (index) {
            this.ruleForm.tag.splice(index, 1)
        },
        removeItems(indexs){
            this.Form.tag.splice(indexs, 1)
        },
        update(){
            this.dialogVisible = true
        },
        handleClose(done){

        },
        // 删除上传图片
        handleRemove(file,fileList){
            var arr =[]
            fileList.map(item=>{
                arr.push(item.response.data)
            })
            this.ruleForm.img = arr
            this.hideUpload = fileList.length>=5
        },
        // 上传图片
        handsuccessForm(res,file,fileList){
            var arr =[]
            fileList.map(item=>{
                arr.push(item.response.data)
            })
            this.ruleForm.img = arr
            this.hideUpload = fileList.length>=5
        },
        handleRemoves(file,fileLists){
            var arr =[]
            fileList.map(item=>{
                arr.push(item.response.data)
            })
            this.ruleForm.img = arr
            this.hideUploads = fileLists.length>=5
        },
        // 上传图片
        handsuccessForms(res,file,fileLists){
            var arr =[]
            fileLists.map(item=>{
                arr.push(item.response.data)
            })
            this.Form.img = arr
            this.hideUploads = fileLists.length>=5
        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.dialogVisibleForm = false
            this.$refs[formName].resetFields();
        }
    },
    beforeDestroy () {
            let that = this;
            clearTimeout(that.timeout);
            clearTimeout(that.timeouts);
            clearTimeout(that.timeoutss)
            clearInterval(that.scont);
            that.scont = null;
            that.timeout = null;
            that.timeouts = null;
            that.timeoutss = null
    },
    destroyed () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearInterval(that.scont);
        clearTimeout(that.timeoutss)
        that.scont = null;
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null
    },
    mounted() {
        this.getapp()
        this.down()
        let that = this;
        that.scont = window.setInterval(() => {
            setTimeout(() => {
                that.getapp()
            }, 0)
        },5000)
    }
}
</script>
<style scoped>
@import './../../assets/css/student/Modelmaking.css';
</style>
<style>
.li_div_img .el-carousel{
    width: 100%;
    height: 100%;
}
.li_div_img .el-carousel__container{
    height: 100%;
}
.li_div_img .el-carousel__arrow{
    display: none!important;
}
.li_div_img .el-carousel__arrow:hover{
    display: none!important;
}
.li_div_img .el-carousel__indicators{
    display: none;
}


.moban_popup .el-dialog__body{
    padding: 30px 50px 10px;
}
.hide .el-upload--picture-card{
    display: none;
}
.hides .el-upload--picture-card{
    display: none;
}
.moban_popup .el-upload--picture-card{
    width: 110px;
    height: 110px;
    line-height: 110px;
}
.moban_popup .el-upload-list--picture-card .el-upload-list__item{
    width: 110px;
    height: 110px;
}
.moban_popup .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.moban_popup .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color:#fff;
}
.moban_popup .el-form-item__content{
    margin-left: 0!important;
}
.moban_popup .el-form-item__label{
    font-weight: bold;
    color: #333;
    width: 100%;
    text-align: left;
}
.moban_popup .el-col {
    float: left;
    margin-top: 31px;
}
.moban_popup .el-col-8{
    margin-top: 0!important;
}
.moban_popup .el-col .el-form-item--small.el-form-item{
    margin-bottom: 0;
}
.moban_popup .el-form-item--small.el-form-item{
    margin-bottom:18px;
}
.moban_popup .el-dialog__footer{
    text-align: center;
}
.moban_popup .el-button--mini{
    width: 80px;
}
.moban_popup .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.moban_popup .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.moban_popup .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.moban_popup .avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>